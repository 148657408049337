.nav-bar {
    background-color: black;
    color: white;
    height: 80px;
    z-index: 1000;
    position: sticky;
    font-family: "Kanit", sans-serif;
}

.menu {
    cursor: pointer;
    &-item {
        padding: 0.5rem;
        transition: font-size 0.1s;

        &:hover {
            cursor: pointer;
            font-size: 1.1rem;
        }
    }
}

.container {
    background-color: black;
}

.signin {
    font-family: "Kanit", sans-serif;
    background: transparent;
    color: white;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.2);
    }
}

.signout {
    font-family: "Kanit", sans-serif;
    background: transparent;
    color: white;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.2);
    }
}

.profile {
    font-family: "Kanit", sans-serif;
    background: transparent;
    color: white;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.2);
    }
}

.ant-btn-default:hover {
    color: black !important;
    background-color: white !important;
    border-color: white !important;
}

.wrapper {
    width: 100%;
    position: fixed;
    z-index: 1000;
}

button {
    margin-bottom: 12px;
}

.ant-menu.ant-menu-inline-collapsed {
    width: 0px;
}

.anticon {
    font-size: 1.5rem;
    vertical-align: -0.3em;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: #001529ce;
}

a{
    text-decoration: none !important;
}