/* MyComponent.css */

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: white !important;
}

.ant-collapse {
    background-color: #12223b !important;
}

.ant-collapse-content {
    background-color: #030b17 !important;;
}

.ant-list-item {
    padding-left: 1rem !important;;
}

.ant-list-item a {
    color: white !important;;
}

.ant-list-item:hover {
    background-color: rgb(28 142 235 / 96%) !important;;
    border-radius: 8px !important;;
}

.ant-list-item:hover a {
    color: white !important;;
}
