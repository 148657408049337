html {
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Kanit", sans-serif;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.993);
    border-radius: 10px;
    background-color: #4b4b4b;
}

::-webkit-scrollbar {
    width: 12px;
    background-color: #4b4b4b;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.993);
    background-color: rgba(0, 0, 0, 0.486);
}

body {
    font-family: "Kanit", sans-serif;
    color: white;
}