/* Layout.css */

.content {
    height: 100%;
    background-color: #05142b;
    color: white;
}

.rc-content {
    min-height: 100vh;
    background-image: url("/src/asset/wallpaper/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: fixed;
    background-attachment: fixed;
    color: white;
}

.rc-header {
    padding: 0;
    height: auto;
    background-color: black;
    z-index: 1000;
    position: sticky;
    top: 0;
}

.ant-layout-footer {
    background-color: black;
    color: white;
}
