.login-form-margin {
    margin-bottom: 16px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.ant-modal-header {
    padding: 16px 24px !important;
    color: rgba(0, 0, 0, 0.85)!important;;
    background: #fff!important;;
    border-bottom: 1px solid #f0f0f0!important;;
    border-radius: 8px 8px 0 0!important;;
}

.login-form-forgot{
    color: 'black' !important;
}