.anime-card {
    border-radius: 8px;
    position: relative;
    background-color: black;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    transition: transform 0.2s;
    font-family: "Kanit", sans-serif;
    color: white;
}

img{
    border-radius: 8px;
    width: 100%;
    height: 350px;
}

.anime-card .anime-image  {
    width: 100%;
}

.anime-card:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.anime-card:hover .box-description {
    height: 44px;
}

.anime-card:hover img {
    opacity: 0.6;
}

.box {
    width: 100%;
    padding: 0.4rem 0.8rem;
    word-wrap: break-word;
    border-radius: 0 0 8px 8px;
    background-color: rgba(0, 0, 0, 0.26);
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
}

.box-description {
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
}

.rating {
    width: fit-content;
    font-size: 0.8rem;
    border-radius: 8px;
    padding: 0.2rem 0.5rem;
    background-color: rgba(48, 47, 47, 0.336);
    color: white;
}

.box-left-top {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}

.ep {
    font-size: 0.8rem;
    border-radius: 8px;
    padding: 0.2rem 0.5rem;
    background-color: rgba(216, 18, 18, 0.68);
    color: white;
}

.box-right-top {
    padding: 0.25rem 0.4rem 0.5rem 0.4rem;
    background-color: #212529;
    border-radius: 8px;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.favorite {
    transition: transform 0.2s;
    color: #bb2649;
}

.favorite i {
    font-size: 1.2rem;
}

.favorite:hover {
    transform: scale(1.2);
}

.bell {
    transition: transform 0.2s;
    color: #52c41a;
}

.bell i {
    font-size: 1.2rem;
}

.bell:hover {
    animation: tilt-shaking 0.25s 2;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
