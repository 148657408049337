.video-detail {
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6110819327731092) 35%,
            rgba(0, 0, 0, 1) 100%
    );
    position: absolute;
    box-shadow: rgb(18, 18, 19) 0px 20px 30px -10px;
    bottom: 0;
    font-family: "Kanit", sans-serif;
}

.player {
    height: 500px;
    position: relative;

}

video {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

.play-button {
    z-index: 100;
    border: none;

    .anticon {
        vertical-align: 0.1em;
    }

    &:hover {
        color: white;
        background-color: #af0616;
        border: none;
    }
}
.play-button:hover{
    background-color:  #af0616 !important;
}
