h3 {
    color: #fff;
    margin-bottom: 0;
    user-select: none;
}

.container-landing {
    padding: 0;
    background-color: #1e1e1ee8;
}

.ant-pagination-item{
    background-color: black !important;
    color: white;
    border-color: black;
}

.ant-pagination-item-active{
    background-color: white !important;
    color: black;
    border-color: black;
}