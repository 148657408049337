.detail {
    min-height: 100vh;
    background-color: #05142b;
}

.image-cover {
    border-radius: 8px;
    background-color: #05142b;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 283px;
        height: 400px;
        border-radius: 8px;
    }
}

.anime-detail-box {
    border-radius: 8px;
    background-color: #00000078;
    padding: 1rem;
    height: fit-content;
}

.title {
    font-size: 1.5rem;
}

.description {
    font-size: 1rem;
    font-weight: 300;
}

.score-box {
    background-color: #f3f3f325;
    border-radius: 8px;

    &-title {
        border-bottom: solid 1px white;
        font-weight: 300;

        font-size: 1rem;
        background-color: #f85b06;
        border-radius: 8px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        text-align: center;
    }
    &-value {
        font-size: 1rem;
        text-align: center;
    }
    width: 50px;
}

.esp-box {
    background-color: #f3f3f325;
    border-radius: 8px;

    &-title {
        border-bottom: solid 1px white;
        font-weight: 300;

        font-size: 1rem;
        background-color: #ce2437;
        border-radius: 8px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        text-align: center;
    }
    &-value {
        font-size: 1rem;
        text-align: center;
    }
    width: 50px;
}

.iframe {
    border-radius: 8px;
}

.ant-btn {
    font-family: "Kanit", sans-serif !important;
}